import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "site-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")
  const _component_get_in_touch = _resolveComponent("get-in-touch")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_page_header, {
      image: "window",
      title: "Contact"
    }),
    _createVNode("main", _hoisted_1, [
      _createVNode(_component_get_in_touch, {
        address: _ctx.contact.address,
        phone: _ctx.contact.phone,
        email: _ctx.contact.email,
        description: _ctx.contact.description
      }, null, 8, ["address", "phone", "email", "description"])
    ])
  ]))
}