
import { defineComponent } from 'vue';
import { getContact, ParsedItem } from '../lib/Contentful'
import PageHeader from '../components/PageHeader.vue'
import GetInTouch from '../components/GetInTouch.vue'

interface ContactData {
  contact: ParsedItem;
  location: string;
}

export default defineComponent({
  name: 'Contact',
  data(): ContactData {
    return {
      contact: null,
      location: window.location.pathname.split("/")[1]
    }
  },
  async mounted() {
    await this.getContact(this.location);
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  methods: {
    async getContact(location: string) {
      this.contact = await getContact(location)
    }
  },
  components: {
    PageHeader,
    GetInTouch
  }
});
